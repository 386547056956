/**
 * These routes should also be defined inside the WPLKMW plugin.
 */

import { gql } from '@apollo/client';

const LOG_IN = gql`
    mutation logIn($login: String!, $password: String!) {
        loginWithCookies(input: {
            login: $login
            password: $password
        }) {
            status
        }
    }
`;
const LOG_OUT = gql`
    mutation logOut {
        logout(input: {}) {
            status
        }
    }
`;
const REGISTER_USER = gql`
    mutation registerUser(
        $email: String!
        $username: String!
        $firstName: String
        $lastName: String
    ) {
        registerUser(
            input: {
                email: $email
                username: $username 
                firstName: $firstName
                lastName: $lastName
            }
        ) {
            user {
                databaseId
            }
        }
    }
`;
const RESET_PASSWORD = gql`
    mutation resetUserPassword(
        $key: String!
        $login: String!
        $password: String!
    ) {
        resetUserPassword(
            input: {
                key: $key
                login: $login
                password: $password
            }
        ) {
            user {
                databaseId
                email
            }
        }
    }
`;
const SEND_PASSWORD_RESET = gql`
    mutation sendPasswordResetEmail(
        $username: String!
    ) {
        sendPasswordResetEmail(
            input: {
                username: $username
            }
        ) { 
            user {
                email
            }
            clientMutationId,
        }
    }
`;

export {
  LOG_IN,
  LOG_OUT,
  REGISTER_USER,
  RESET_PASSWORD,
  SEND_PASSWORD_RESET,
};
