import React from 'react';

import { Link } from 'react-router-dom';
import NavAccountTools from './NavAccountTools';
import logo from '../../images/logo.svg';

const Header = () => {
  const notProduction = process.env.REACT_APP_VERCEL_ENV && process.env.REACT_APP_VERCEL_ENV !== 'production';

  return (
    <div className="z-5 border border-transparent border-b-gray-300">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="flex justify-between py-3">
          <div className="flex-1 flex items-center">
            <Link to="/" className="flex items-center">
              <img
                className="h-7 sm:h-9 mr-2 block w-auto"
                src={logo}
                alt="LandingKit Logo"
              />
              <h1 className="text-neutral-900 font-bold text-lg sm:text-xl">
                {notProduction && <span>alpha.</span>}
                LandingKit
                <span className="text-gray-500">.io</span>
              </h1>
            </Link>
          </div>
          <div className="flex items-center">
            <NavAccountTools />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
