import React, { ReactElement, Suspense, useEffect } from 'react';
import LargeSpinner from '../Common/LargeSpinner';

type Props = {
  componentPath: string, // Import
  name: string,
  loaderFallback?: ReactElement | undefined // loaderFallback (optional)
}

/**
 * Lazy-loaded Component.
 *
 * @param {string} componentPath Component path - relative to the `./Components` directory.
 * @param {string} loaderFallback Loader fallback - component to replace the default loader.
 * @param {string} name Route name - visible in the tab title.
 *
 * @constructor
 */
const LazyLoadedRoute: React.FC<Props> = ({ componentPath, loaderFallback, name }) => {
  const Component = React.lazy(() => import(`../Pages/${componentPath}`));

  const fallback = loaderFallback || <LargeSpinner />;

  useEffect(() => {
    document.title = `${process.env.REACT_APP_PUBLIC_DOC_TITLE_PREFIX || 'LandingKit.io'} - ${name}`;
  });

  return (
    <Suspense fallback={fallback}>
      <>
        {/* {fallback} */}
        <Component />
      </>
    </Suspense>
  );
};

export default LazyLoadedRoute;

LazyLoadedRoute.defaultProps = {
  loaderFallback: undefined,
};
