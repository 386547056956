import React from 'react';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import Layout from '../Layout/Layout';

const DashboardMock = () => (
  <Layout>
    <main className="bg-gray-50 grow">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        {[1].map((i) => (
          <div key={i} className="py-5">
            <div className="py-5 animate-pulse">
              <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                <div className="ml-4 mt-2">
                  <div className="h-6 w-24 bg-gray-200 rounded" />
                </div>
                <div className="ml-4 mt-2 flex-shrink-0 flex space-x-3">
                  <div className="h-9 w-[180px] bg-blue-200 rounded" />
                  <div className="h-9 w-[180px] bg-gray-100 rounded" />
                </div>
              </div>
            </div>
            <div className="bg-white shadow rounded-lg divide-y divide-gray-200">
              <ul className="divide-y divide-gray-200">
                <li className="flex px-4 py-4 sm:px-6">
                  <div>
                    <div className="my-2 h-4 w-72 bg-gray-200 rounded" />

                    <div className="mt-4 sm:flex sm:justify-between">
                      <div className="sm:flex space-x-3 text-gray-600 text-sm">
                        <div className="mt-1 h-5 w-44 bg-gray-200 rounded" />
                      </div>
                    </div>
                  </div>
                  <div className="ml-auto items-center flex">
                    <div className="relative">
                      <div>
                        <span className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent animate-pulse">
                          <EllipsisHorizontalIcon className="h-5 w-5" />
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        ))}
      </div>
    </main>
  </Layout>
);

export default DashboardMock;
