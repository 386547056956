import React from 'react';
import { classNames } from '../../Utils/utils';
import Spinner from './Spinner';

type Props = {
  className?: string;
  type?: 'button' | 'submit';
  onClick?: () => void;
  loading?: boolean;
  children: React.ReactChild | React.ReactChild[]
  disabled?: boolean,
  isPrimary?: boolean,
  isSecondary?: boolean,
  isTertiary?: boolean,
  isLink?: boolean,
  isDanger?: boolean,
}

function Button({
  type,
  loading,
  onClick,
  className,
  children,
  disabled,
  isPrimary,
  isSecondary,
  isTertiary,
  isLink,
  isDanger,
}: Props) {
  const outlineClasses = 'focus:outline-none focus:ring-2 focus:ring-offset-2 ';
  const layoutClasses = 'px-3 py-2 border rounded flex justify-center';
  const textClasses = 'text-sm font-normal';

  const isDefault = isPrimary
      || ![isDanger, isLink, isPrimary, isTertiary, isSecondary].some((el) => el);

  const typeClasses = classNames(
    isDefault ? 'border-transparent text-white bg-blue-500 hover:bg-sky-500 focus:ring-blue-500' : '',
    isDanger ? 'border-transparent text-white bg-red-500 hover:bg-rose-500 focus:ring-red-500' : '',
    isSecondary ? 'text-neutral-700 border-neutral-700 bg-transparent hover:bg-blue-500 hover:text-white hover:border-transparent' : '',
    isTertiary ? 'border-gray-300 text-gray-500 hover:border-gray-500 hover:text-gray-800' : '',
    isLink ? 'border-0 font-medium' : '',
  );

  const buttonClasses = classNames(
    layoutClasses,
    outlineClasses,
    textClasses,
    typeClasses,
    className || '',
    loading || disabled ? 'opacity-75 cursor-not-allowed' : '',
  );

  const innerClasses = 'flex items-center';
  const innerContent = (
    <span className={innerClasses}>
      {loading && <Spinner className="-ml-1 mr-3" />}
      {children}
    </span>
  );

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      disabled={loading || disabled}
      className={buttonClasses}
    >
      {innerContent}
    </button>
  );
}

Button.defaultProps = {
  type: 'button',
  onClick: () => { },
  loading: false,
  className: '',
  disabled: false,
  isPrimary: false,
  isSecondary: false,
  isTertiary: false,
  isLink: false,
  isDanger: false,
};

export default Button;
