import React, { ErrorInfo } from 'react';
import Container from '../Layout/Container';

class ErrorBoundary extends React.Component<{}, { hasError: boolean, error: Error | null }> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // TODO: log to sentry maybe.
    console.log(error);
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Container>
          <div className="bg-red-100 border border-red-200 text-red-900 p-2 rounded">
            <h1>Something went wrong.</h1>
            {error && <p>{error.message}</p>}
          </div>
        </Container>
      );
    }
    return <>{children}</>;
  }
}

export default ErrorBoundary;
