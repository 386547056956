import { gql } from '@apollo/client';

const CORE_PATTERN_FIELDS = gql`fragment CorePatternFields on Pattern {
    id
    databaseId
    content(format: RAW)
    date
    modified
    status
    title
    patternTypes {
        nodes {
            slug       
        }
    }
    patternThumbnail
}
`;

export {
  // eslint-disable-next-line import/prefer-default-export
  CORE_PATTERN_FIELDS,
};
