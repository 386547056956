import RouteType from '../Types/Route';

const ACCOUNT_ROUTES: RouteType[] = [
  {
    to: '/dashboard',
    name: 'Dashboard',
    component: 'Dashboard',
  },
  {
    to: '/profile',
    name: 'Your Profile',
    component: 'Profile',
  },
];

const ADDITIONAL_ROUTES: RouteType[] = [
  {
    to: '/',
    name: 'Home',
    component: 'Home',
  },
  {
    to: '/login',
    name: 'Login',
    component: 'Login',
  },
  {
    to: '/editor/:id',
    name: 'Editor',
    component: 'Editor',
  },
  {
    to: '/domain-map/:id',
    name: 'Domain Map',
    component: 'DomainMap',
  },
  {
    to: '/quick-start',
    name: 'Starter Templates',
    component: 'StarterTemplates',
  },

  {
    to: '/quick-start/:id',
    name: 'Editor',
    component: 'Starter',
  },
  {
    to: '/register',
    name: 'Register',
    component: 'Register',
  },
  {
    to: '/set-password',
    name: 'Set Password',
    component: 'SetPassword',
  },
  {
    to: '/recover',
    name: 'Recover',
    component: 'Recover',
  },
  {
    to: '/*',
    name: '404 - Not Found',
    component: 'NotFound',
  },
];

const MAX_IMAGES = 50;

const ALL_ROUTES: RouteType[] = [...ACCOUNT_ROUTES, ...ADDITIONAL_ROUTES];

const LOCAL_STORAGE_KEY = 'lkio-tmp-content';

const EXPIRED_LOGIN = 'expired_login';

export {
  MAX_IMAGES,
  ACCOUNT_ROUTES,
  ALL_ROUTES,
  LOCAL_STORAGE_KEY,
  EXPIRED_LOGIN,
};
