import { Link, useNavigate } from 'react-router-dom';
import { RocketLaunchIcon } from '@heroicons/react/24/solid';
import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { useMutation } from '@apollo/client';
import { UserIcon } from '@heroicons/react/24/outline';
import { ACCOUNT_ROUTES } from '../../Utils/constants';
import { classNames } from '../../Utils/utils';
import useAuth from '../../Hooks/useAuth';
import { LOG_OUT } from '../../lib/mutations/unauth';
import Button from '../Common/button';
import { GET_USER } from '../../lib/queries';

export default function NavAccountTools() {
  const navigate = useNavigate();
  const { loading, loggedIn } = useAuth();

  const [logOut] = useMutation(LOG_OUT, {
    refetchQueries: [GET_USER],
    update(cache) {
      cache.evict({ id: 'ROOT_QUERY' });
      cache.gc();
    },
  });

  const handleLogOut = () => {
    logOut().then((r) => {
      navigate('/');
    }).catch((err) => {
      console.error(err);
    });
  };

  if (loading) {
    return (
      <div className="flex items-center space-x-2 animate-pulse">
        <div
          className="justify-center inline-flex items-center px-12 py-2 text-sm font-medium rounded text-transparent bg-blue-200 border border-transparent"
        >
          ...
        </div>
        <div className="inline-flex items-center px-4 py-2 border border-transparent">
          <span className="h-5 bg-neutral-200 w-12 rounded" />
        </div>
      </div>
    );
  }

  if (!loggedIn) {
    return (
      <div className="flex items-center space-x-2">
        <Link to="/quick-start">
          <Button type="button">
            <RocketLaunchIcon className="hidden sm:block -ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            <span>Get Started</span>
          </Button>
        </Link>
        <Link to="/login">
          <Button isLink type="button">
            <UserIcon className="hidden sm:block -ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Login
          </Button>
        </Link>
      </div>
    );
  }
  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className="inline-flex px-4 py-2 border rounded flex items-center justify-center text-neutral-700 border-neutral-700 bg-transparent hover:bg-blue-500 hover:text-white hover:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 text-sm font-normal">
          <span className="sr-only">Open user menu</span>
          <UserIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Account
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-lg"
        >
          {ACCOUNT_ROUTES.map(({ to, name }) => (
            <Menu.Item key={to}>
              {({ active }) => {
                const linkClasses = classNames(
                  'block px-4 py-2 text-sm text-gray-700',
                  active ? 'text-blue-500' : '',
                );

                return (
                  <Link className={linkClasses} to={to}>
                    {name}
                  </Link>
                );
              }}
            </Menu.Item>
          ))}
          <Menu.Item>
            {({ active }) => {
              const linkClasses = classNames(
                'block px-4 py-2 text-sm text-gray-700 w-full text-left',
                active ? 'text-blue-500' : '',
              );

              return (
                <button
                  type="button"
                  onClick={handleLogOut}
                  className={linkClasses}
                >
                  Sign Out
                </button>
              );
            }}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
