import { gql } from '@apollo/client';

const CORE_PAGE_FIELDS = gql`fragment CorePageFields on Page {
    title
    status
    modified
    date
    id
    databaseId
    content(format: RAW)
    domain
    validDNS
}
`;

// eslint-disable-next-line import/prefer-default-export
export { CORE_PAGE_FIELDS };
