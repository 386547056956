import React from 'react';
import useNotification from '../../Hooks/useNotification';
import Notification from '../Common/Notification';

export default function Notifications() {
  const { notifications } = useNotification();

  return (
    <div
      aria-live="assertive"
      className="fixed bottom-0 right-0 w-full flex items-start px-4 py-6 pointer-events-none z-xl"
    >
      <div className="w-full flex flex-col space-y-4 items-end">
        {notifications.map(({
          text, type, timeout, title, uid,
        }) => (
          <Notification
            key={uid}
            text={text}
            title={title}
            type={type}
            timeout={timeout}
            uid={uid}
          />
        ))}
      </div>
    </div>
  );
}
