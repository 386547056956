/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import {
  CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon, InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import useNotification from '../../Hooks/useNotification';
import { NotificationType } from '../../Types/Generic';
import { classNames } from '../../Utils/utils';

export default function Notification({
  type, timeout, title, text, uid,
} : NotificationType) {
  const [show, setShow] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const { dismissNotification } = useNotification();

  useEffect(() => {
    if (isHovered) {
      return () => {};
    }
    const dismissTO = setTimeout(dismiss, timeout || 2500);

    return () => { clearTimeout(dismissTO); };
  }, [isHovered]);

  const dismiss = () => {
    setShow(false);
    setTimeout(() => {
      dismissNotification(uid);
    }, 500);
  };

  const playDismissal = () => {
    setIsHovered(false);
  };

  const holdDismissal = () => {
    setIsHovered(true);
  };

  let Icon;
  let iconClasses;

  switch (type) {
    case 'error':
      Icon = ExclamationCircleIcon;
      iconClasses = 'text-rose-400';
      break;
    case 'success':
      Icon = CheckCircleIcon;
      iconClasses = 'text-green-400';
      break;
    case 'warning':
      Icon = ExclamationTriangleIcon;
      iconClasses = 'text-orange-400';
      break;
    case 'info':
    default:
      Icon = InformationCircleIcon;
      iconClasses = 'text-blue-400';
  }

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className="max-w-sm w-full bg-neutral-900 text-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
        onMouseEnter={holdDismissal}
        onMouseLeave={playDismissal}
      >
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <Icon className={classNames(iconClasses, 'h-6 w-6')} aria-hidden="true" />
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-white">{title}</p>
              {text && <p className="mt-1 text-sm text-gray-200">{text}</p>}
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                type="button"
                className="rounded-md inline-flex text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={dismiss}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
