import { gql } from '@apollo/client';

const CORE_USER_FIELDS = gql`fragment CoreUserFields on User{
    id
    databaseId
    firstName
    lastName
    email
    limits {
        pages
        domains
        images
        privileges
    }
    entities {
        pages
        domains
        images
    }
}
`;

export {
  // eslint-disable-next-line import/prefer-default-export
  CORE_USER_FIELDS,
};
