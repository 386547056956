import { gql } from '@apollo/client';
import { CORE_DOMAIN_FIELDS } from './fragments/domain';
import { CORE_PAGE_FIELDS } from './fragments/page';
import { CORE_USER_FIELDS } from './fragments/user';
import { CORE_MEDIA_ITEM_FIELDS } from './fragments/mediaItem';
import { CORE_PATTERN_FIELDS } from './fragments/pattern';

const GET_USER = gql`
    ${CORE_USER_FIELDS}
    query getUser {
        viewer {
            ...CoreUserFields
        }
    }
`;

const GET_ENTITIES = gql`
    ${CORE_DOMAIN_FIELDS}
    ${CORE_PAGE_FIELDS}
    query getEntities {
        pages(first: 50) {
            nodes {
                ...CorePageFields
            }
        }
        domains(first: 50) {
            nodes {
                ...CoreDomainFields
            }
        }
    }
`;

const GET_PAGE_DATA = gql`
    ${CORE_PAGE_FIELDS}
    query getPageData (
        $id: ID!
    ) {
        page(id: $id) {
            ...CorePageFields
        }
    }
`;
const GET_PAGE_DATA_REFRESH_DNS = gql`
    query getPageData (
        $id: ID!
    ) {
        page(id: $id) {
            refreshDNS
        }
    }
`;

const GET_PATTERN_DATA = gql`
    query getPatternData (
        $id: ID!
    ) {
        page : pattern(id: $id)  {
            id
            title
            content(format: RAW)
        }
    }
`;

const GET_DOMAIN_DATA = gql`
    ${CORE_PAGE_FIELDS},
    ${CORE_DOMAIN_FIELDS}
    query getDomainData (
        $id: ID!
    ) {
        domain(id: $id) {
            ...CoreDomainFields,
        }
        pages {
            nodes {
                ...CorePageFields
            }
        }
    }
`;

const GET_DOMAIN = gql`
    ${CORE_DOMAIN_FIELDS}
    query getDomainData (
        $id: ID!
    ) {
        domain(id: $id) {
            ...CoreDomainFields,
        }
    }
`;

const GET_MEDIA_ITEMS = gql`
    ${CORE_MEDIA_ITEM_FIELDS}
    query getMediaItems {
        mediaItems(
            first: 100
        ) {
            nodes {
                ...CoreMediaItemFields,
            }
        }
    }
`;

const GET_PUBLIC_TEMPLATES = gql`
    query publicTemplates {
        templates: publicTemplates {
            id
            name,
            screenshot
            url
            categories
        }
    }
`;

const GET_PUBLIC_TEMPLATE_DATA = gql`
    query publicTemplateData( $id: ID!) {
        page: publicTemplateData(id: $id) {
            content
            name,
            settings: pageSettings {
                background {
                    image {
                        id
                        url
                        position
                        size
                        fixed
                        overlay
                        overlayType
                        overlayColor
                        overlayGradient
                    }
                    color
                    type
                    gradient
                }
                fonts {
                    body {
                        family
                        weights
                    }
                    headings {
                        family
                        weights
                    }
                }
            }
        }
    }
`;

const GET_PAGE_SETTINGS = gql`
    query getPageSettings($id: ID!) {
        page(id: $id) {
            settings {
                background {
                    image {
                        id
                        url
                        position
                        size
                        fixed
                        overlay
                        overlayType
                        overlayColor
                        overlayGradient
                    }
                    color
                    type
                    gradient
                }
                fonts {
                    body {
                        family
                        weights
                    }
                    headings {
                        family
                        weights
                    }
                }
            }
        }
    }
`;

const GET_PATTERNS = gql`
    ${CORE_PATTERN_FIELDS}
    query getPatterns {
        patterns(first: 100, where: {stati: [DRAFT, PUBLISH]}) {
            nodes {
                ...CorePatternFields
            }
        }
        patternTypes {
            nodes {
                name
                slug
            }
        }
    }
`;

const GET_PUBLIC_PATTERNS = gql`
    ${CORE_PATTERN_FIELDS}
    query getPatterns {
        patterns(first: 100, where: {status: PUBLISH}) {
            nodes {
                ...CorePatternFields
            }
        }
        patternTypes(where: {hideEmpty: true}) {
            nodes {
                name
                slug
                count
            }
        }
    }
`;

export {
  GET_ENTITIES,
  GET_PAGE_DATA,
  GET_PAGE_DATA_REFRESH_DNS,
  GET_DOMAIN_DATA,
  GET_DOMAIN,
  GET_USER,
  GET_MEDIA_ITEMS,
  GET_PUBLIC_TEMPLATES,
  GET_PUBLIC_TEMPLATE_DATA,
  GET_PAGE_SETTINGS,
  GET_PATTERNS,
  GET_PATTERN_DATA,
  GET_PUBLIC_PATTERNS,
};
