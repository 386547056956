import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';

if (process.env.REACT_APP_VERCEL_ENV) {
  Sentry.init({
    dsn: 'https://0faedc8d206547799f8f76405bead803@o1050139.ingest.sentry.io/6197751',
    integrations: [new BrowserTracing()],
    tracesSampleRate: process.env.REACT_APP_VERCEL_ENV === 'production' ? 0.5 : 1.0,
    environment: process.env.REACT_APP_VERCEL_ENV,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
