import { gql } from '@apollo/client';

const CORE_DOMAIN_FIELDS = gql`fragment CoreDomainFields on Domain {
    id
    databaseId
    title
    status 
    modified
    date
    mappings {
        root {
            post_id
        }
    }
}
`;

const DOMAIN_MAPPING_FIELDS = gql`fragment DomainMappingFields on Domain {
    mappings {
        root {
            post_id
        }
    }
}
`;

export {
  CORE_DOMAIN_FIELDS,
  DOMAIN_MAPPING_FIELDS,
};
