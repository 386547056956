import React, { ReactElement } from 'react';
import { BrowserRouter as RouterProvider, Route, Routes } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import client from './lib/apolloClient';

import { AuthProvider } from './Hooks/useAuth';

import { NotificationProvider } from './Hooks/useNotification';

import './App.scss';

import LazyLoadedRoute from './Components/HOC/LazyLoadedRoute';
import { ALL_ROUTES } from './Utils/constants';

// Mocks to replace loading state.
import LoginMock from './Components/PageMocks/LoginMock';
import RegisterMock from './Components/PageMocks/RegisterMock';
import RecoverMock from './Components/PageMocks/RecoverMock';
import DashboardMock from './Components/PageMocks/DashboardMock';
import SetPasswordMock from './Components/PageMocks/SetPasswordMock';

const App = () => {
  const mockMaps : {[k : string] : ReactElement} = {
    '/login': <LoginMock />,
    '/register': <RegisterMock />,
    '/recover': <RecoverMock />,
    '/dashboard': <DashboardMock />,
    '/set-password': <SetPasswordMock />,
  };

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <RouterProvider>
          <NotificationProvider>
            <div className="App flex flex-col grow antialiased ">
              <Routes>
                {ALL_ROUTES.map(({
                  to,
                  component,
                  name,
                }) => {
                  const lazyLoadedComponent = (
                    <LazyLoadedRoute
                      loaderFallback={mockMaps[to]}
                      componentPath={component}
                      name={name}
                    />
                  );

                  return <Route key={to} path={to} element={lazyLoadedComponent} />;
                })}
              </Routes>
            </div>
          </NotificationProvider>
        </RouterProvider>
      </AuthProvider>
    </ApolloProvider>
  );
};

export default App;
