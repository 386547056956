import React, { createContext, ReactNode, useContext } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { GET_USER } from '../lib/queries';

type AuthContextType = {
  loggedIn: Boolean,
  user: Record<string, any>,
  loading: Boolean,
  error: ApolloError | undefined,
  limits: Record<string, number | boolean>,
  entities: Record<string, number>
}

const AuthContext = createContext({} as AuthContextType);

export function AuthProvider({ children }: { children: ReactNode }) {
  const { data, loading, error } = useQuery(GET_USER);

  const user = data?.viewer;
  const loggedIn = Boolean(user);

  const { limits, entities } = user || { limits: null, entities: null };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    loggedIn,
    user,
    loading,
    error,
    limits,
    entities,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

const useAuth = () => useContext(AuthContext);

export default useAuth;
