import React from 'react';
import useNotification from '../../Hooks/useNotification';
import Button from '../Common/button';
import { NotificationTypes } from '../../Types/Generic';
import Container from './Container';

const navigation = [
  { name: 'Themeisle', href: 'https://themeisle.com' },
  { name: 'CodeinWP', href: 'https://codeinwp.com' },
];

const Footer = () => {
  const { addNotification } = useNotification();

  return (
    <footer className="bg-white border-t border-gray-100 z-10 mt-auto text-center text-base text-gray-500 py-9">
      <Container className="md:flex md:items-center md:justify-between">
        <div className="flex justify-center space-x-6 md:order-3">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="hover:text-gray-700"
            >
              {item.name}
            </a>
          ))}
        </div>

        <div className="md:order-2 mt-5 md:mt-0">
          Contact:
          {' '}
          <a href="mailto:hi@landingkit.io" className="hover:text-gray-700">hi@landingkit.io</a>
        </div>

        <div className="mt-5 md:mt-0 md:order-1">
          <p>
            <span>
              &copy;
              {new Date().getFullYear()}
              {' '}
              <a
                target="_blank"
                className="text-blue-400"
                href="https://vertistudio.com"
                rel="noreferrer"
              >
                VertiStudio
              </a>
              {' '}
              - All Rights reserved.
            </span>
          </p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
