import React from 'react';
import classnames from 'classnames';
import Spinner from './Spinner';

type Props = {
  classNames?:string | undefined
}

const LargeSpinner = ({ classNames }: Props) => (
  <div className={classnames('absolute inset-0 flex w-full justify-center items-center', classNames || '')}>
    <Spinner className="w-10 h-10" />
  </div>
);

LargeSpinner.defaultProps = {
  classNames: undefined,
};

export default LargeSpinner;
