import React, { ReactChild, ReactChildren, ReactElement } from 'react';
import { classNames } from '../../Utils/utils';

type Props = {
    className? : string
    children: ReactChild | ReactChild[]
}

function Container({ className, children } : Props) {
  return (
    <div className={classNames(className || '', 'max-w-7xl w-full mx-auto px-2 sm:px-6 lg:px-8')}>
      {children}
    </div>
  );
}

Container.defaultProps = {
  className: '',
};

export default Container;
