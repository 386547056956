import React, { ReactChild, ReactElement } from 'react';
import Header from './Header';
import Footer from './Footer';
import ErrorBoundary from '../HOC/ErrorBoundary';
import Notices from './Notices';

type Props = {
    disableHeader?: boolean;
    disableFooter?: boolean;
    children: ReactChild | ReactChild[]
}

export default function Layout({ disableHeader = false, disableFooter = false, children }: Props) {
  return (
    <>
      {!disableHeader && <Header />}
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
      <Notices />
      {!disableFooter && <Footer />}
    </>
  );
}

Layout.defaultProps = {
  disableHeader: false,
  disableFooter: false,
};
