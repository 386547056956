/* eslint-disable no-param-reassign, max-len, no-underscore-dangle */
import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

import { TemplateData } from '../Types/Generic';
import { EXPIRED_LOGIN, LOCAL_STORAGE_KEY } from './constants';

/**
 * Generate classnames based on ternary expressions.
 *
 * @param {string[]} classes strings to evaluate.
 */
const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ');

/**
 * Format date to readable.
 * @param {Date} date input date.
 */
const formatDate = (date: Date) => new Date(date).toLocaleDateString('en-US', {
  month: '2-digit',
  year: 'numeric',
  day: '2-digit',
});

/**
 * Transform an array to an object with given key.
 *
 * @param {object[]} arr the objects array input.
 * @param {string} key key from objects that should be used.
 */
const arrayToObject = (arr: [], key: string) => arr.reduce((output, item) => {
  /* eslint-disable-next-line no-param-reassign */
  output[item[key]] = item;

  return output;
}, {});

/**
 * Transform number to readable filesize format
 *
 * @param {number} bytes given bytes.
 * @param {number} decimals how many decimals to show.
 */
const bytesToKb = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Get an array of chunks from input array.
 * @param {array} inputArray the array to chunk.
 * @param {number} perChunk how many items per chunk.
 */
const chunkArray = <T>(inputArray: Array<T>, perChunk: number = 2): Array<Array<T>> => inputArray.reduce(
  (resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  },
  [] as Array<Array<T>>,
);

const columnTemplateFromLayoutValue = (layout: string) => {
  const cols = layout.split('-').map(Number);
  return cols.map((col) => `${col}fr`).join(' ');
};

const stripTypename = <T extends Record<string, any>>(obj: T): T => {
  if (Array.isArray(obj)) {
    return obj;
  }

  if (!isObject(obj)) {
    return obj;
  }

  const copy = { ...obj };

  if (copy.__typename) {
    delete copy.__typename;
  }

  Object.keys(copy).forEach((key: keyof T) => {
    if (copy[key] && isObject(copy[key])) {
      copy[key] = stripTypename(copy[key]);
    }
  });

  return copy;
};

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item: any): boolean {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param sources
 */
export function mergeDeep(target: { [x: string]: any; }, ...sources: { [x: string]: any; }[]): { [x: string]: any; } {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    // @ts-ignore
    Object.keys(source).forEach((key) => {
      // @ts-ignore
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        // @ts-ignore
        mergeDeep(target[key], source[key]);
      } else {
        // @ts-ignore
        Object.assign(target, { [key]: source[key] });
      }
    });
  }
  return mergeDeep(target, ...sources);
}

const getLocalStorageTemplates = (): Record<string, TemplateData> | null => {
  const localData = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (!localData) {
    return null;
  }

  const templatesObject: { [key: string]: TemplateData } = JSON.parse(localData);

  if (!isObject(templatesObject)) {
    return null;
  }

  Object.keys(templatesObject).forEach((key) => {
    if (isObject(templatesObject[key])
      && templatesObject[key].editorContent
      && templatesObject[key].pageTitle
      && templatesObject[key].pageSettings) {
      return;
    }

    delete templatesObject[key];
  });

  if (Object.keys(templatesObject).length < 1) {
    return null;
  }

  return templatesObject;
};

/**
  * Checks if the login is expired on an incoming error.
 *
 * @param {ApolloError} err Error incoming from query or mutation.
 */
const isLoginExpiredError = (err: ApolloError) => err.message && err.message.includes(EXPIRED_LOGIN);

const urlHasPath = (url: string) => {
  const urlObj = new URL(url);
  return urlObj.pathname !== '/';
};

export {
  chunkArray,
  bytesToKb,
  arrayToObject,
  formatDate,
  classNames,
  columnTemplateFromLayoutValue,
  stripTypename,
  getLocalStorageTemplates,
  isLoginExpiredError,
  urlHasPath,
};
