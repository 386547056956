import React from 'react';

const RegisterMock = () => (
  <div className="grow bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div className="sm:mx-auto sm:w-full sm:max-w-md animate-pulse">
      <div className="mt-6 h-8 w-1/2 mx-auto bg-gray-200 rounded" />
    </div>

    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">
        <div className="space-y-8 animate-pulse">

          <div className="space-y-1">
            <div className="mt-2 h-4 w-20 bg-gray-200 rounded" />
            <div className="mt-1 h-8 bg-gray-200 rounded-md" />
          </div>

          <div className="flex items-center justify-between">
            <div className="h-4 w-40 bg-blue-300 rounded" />
          </div>
          <div>
            <div>
              <div className="h-9 bg-blue-300 rounded-md" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2 flex text-sm items-center justify-end group">
        <span className="bg-gray-200 w-16 h-5 rounded" />
      </div>
    </div>
  </div>
);

export default RegisterMock;
