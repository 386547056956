import { gql } from '@apollo/client';

const CORE_MEDIA_ITEM_FIELDS = gql`fragment CoreMediaItemFields on MediaItem {
    id
    thumbSrc: sourceUrl(size: THUMBNAIL)
    src: sourceUrl(size: LARGE)
    previewSrc: sourceUrl(size: MEDIUM)
    srcSet(size: LARGE)
    title
    altText
    fileSize
    mediaDetails {
        height
        width
    }
    sizes
}
`;

export {
  // eslint-disable-next-line import/prefer-default-export
  CORE_MEDIA_ITEM_FIELDS,
};
