import {
  ApolloClient, InMemoryCache, createHttpLink, ApolloLink,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

const uri = process.env.REACT_APP_PUBLIC_WORDPRESS_API_URL;

const link = createHttpLink({
  uri,
  credentials: 'include',
});

const uploadLink = createUploadLink({
  uri,
  credentials: 'include',
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  // @ts-ignore
  link: ApolloLink.from([uploadLink]),
});

export default client;
