import React from 'react';

const LoginMock = () => (
  <div className="min-h-full flex h-screen">
    <div
      className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 animate-pulse"
    >
      <div className="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <div className="mt-6 h-8 bg-gray-200 rounded" />
          <div className="mt-2 h-4 w-20 bg-gray-300 rounded" />
        </div>

        <div className="mt-8">
          <div className="mt-6">
            <div className="space-y-6">
              <div>
                <div className="mt-2 h-4 w-20 bg-gray-200 rounded" />
                <div className="mt-1 h-8 bg-gray-200 rounded-md" />
              </div>

              <div className="space-y-1">
                <div className="mt-2 h-4 w-20 bg-gray-200 rounded" />
                <div className="mt-1 h-8 bg-gray-200 rounded-md" />
              </div>

              <div className="flex items-center justify-between">
                <div className="h-4 w-40 bg-blue-300 rounded" />
              </div>

              <div>
                <div className="h-9 bg-blue-300 rounded-md" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2 flex text-sm items-center justify-end group">
        <span className="bg-gray-200 w-16 h-5 rounded" />
      </div>
    </div>
    <div className="hidden lg:block relative w-0 flex-1 bg-blue-100" />
  </div>
);

export default LoginMock;
