import React, {
  createContext, ReactNode, useContext, useState,
} from 'react';
import { NotificationType, NotificationTypes } from '../Types/Generic';

type NotificationContextType = {
    notifications: NotificationType[]
    dismissNotification: (uid: number) => void
    addNotification: ({
      text, type, timeout, title,
    } : AddNotificationParams) => void
}

type AddNotificationParams = {
  text?: string,
  timeout?: number,
  title: string
  type?: NotificationTypes,
}

const NotificationContext = createContext({} as NotificationContextType);

export function NotificationProvider({ children }: {children: ReactNode}) {
  const [notifications, setNotifications] = useState([] as NotificationType[]);

  const addNotification = (upcoming: AddNotificationParams) => {
    setNotifications([...notifications, { ...upcoming, type: upcoming.type || 'success', uid: new Date().valueOf() }]);
  };

  const dismissNotification = (idToDismiss: number) => {
    setNotifications((old) => old.filter(({ uid }) => uid !== idToDismiss));
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    notifications,
    addNotification,
    dismissNotification,
  };

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
}

const useNotification = () => useContext(NotificationContext);

export default useNotification;
